import logo from "@/images/logo_300px.png";
import { useLocation } from "@reach/router";
import React from "react";
import Helmet from "react-helmet";

type SEOProps = {
  title?: string;
};

const SEO: React.FC<SEOProps> = ({ title: titleProp }) => {
  const title = titleProp ? `${titleProp} | M.D. Design & Development` : "MDDD";
  const { origin } = useLocation();

  const imageUrl = `${origin}${logo}`;

  return (
    <Helmet
      htmlAtrributes={{
        lang: "nl",
        title,
      }}
    >
      <title>{title}</title>

      <meta httpEquiv="language" content="nl" />

      <meta name="og:title" content={title} />
      <meta name="og:description" content="Het klantenportaal van MDDD!" />

      <meta name="og:image" content={imageUrl} />
      <meta name="og:image:secure" content={imageUrl} />
      <meta name="og:image:alt" content="MDDD Logo" />
      <meta name="og:image:width" content="300px" />
      <meta name="og:image:height" content="300px" />

      <meta name="robots" content="nofollow,noindex" />
      <meta name="googlebot" content="nofollow,noindex" />
    </Helmet>
  );
};

export default SEO;
